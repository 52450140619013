import { Injectable, Signal, signal } from '@angular/core';

import { Document } from '../../../domain/document/entities/document';

@Injectable()
export class SignHandler {
  private readonly isOnlyViewMode = signal<boolean>(false);
  private readonly document = signal<Document | null>(null);
  private readonly loading = signal(false);

  getDocument(): Signal<Document | null> {
    return this.document.asReadonly();
  }

  setDocument(document: Document): void {
    this.document.set(document);
  }

  getIsOnlyViewMode(): Signal<boolean> {
    return this.isOnlyViewMode.asReadonly();
  }

  setIsOnlyViewMode(value: boolean): void {
    this.isOnlyViewMode.set(value);
  }

  getLoading(): Signal<boolean> {
    return this.loading.asReadonly();
  }

  setLoading(value: boolean): void {
    this.loading.set(value);
  }
}
